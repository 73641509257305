@font-face {
  font-family: "Konnect";
  src: url("../assets/konnect/Konnect-Light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Konnect";
  src: url("../assets/konnect/Konnect-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Konnect";
  src: url("../assets/konnect/Konnect-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Konnect";
  src: url("../assets/konnect/Konnect-Bold.otf");
  font-weight: bold;
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(-9px);
  }
  35% {
    transform: translateY(-9px) rotate(17deg);
  }
  55% {
    transform: translateY(-9px) rotate(-17deg);
  }
  65% {
    transform: translateY(-9px) rotate(17deg);
  }
  75% {
    transform: translateY(-9px) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes shaking {
  0% {
    transform: translateX(0);
  }
  35% {
    transform: rotate(17deg);
  }
  55% {
    transform: rotate(-17deg);
  }
  65% {
    transform: rotate(17deg);
  }
  75% {
    transform: rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: "Konnect";
  --header-height: 84px;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  display: flex;
  transition: 0.2s ease-in-out;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  padding: 4px;
  font-family: "Konnect";
  transform: translateY(0) scale(1);
}

button:active {
  transform: translateY(1px) scale(0.99);
}

h1,
h2,
h3,
h4,
p,
a,
div {
  font-family: "Konnect";
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-weight: 400;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
